<template>
  <div>
    <div class="custom-search mb-2">
      <b-row>
        <b-col md="4">
          <label for="lead-type"> Выберите пользователя: </label>
          <b-form-select
            id="lead-type"
            :options="usersList"
            v-model="filters.user"
            @change="filterByUser"
          >
          </b-form-select>
        </b-col>
      </b-row>
    </div>
    <projects-table :projects="projectsList" />
  </div>
</template>

<script>
import { BFormSelect, BRow, BCol } from "bootstrap-vue";
import ProjectsTable from "./ProjectsTable.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    ProjectsTable,
    BFormSelect,
    BRow,
    BCol,
  },
  data() {
    return {
      filters: {},
    };
  },
  computed: {
    ...mapGetters("admin", ["getUsers", "getProjects"]),
    projectsList() {
      let projects = [];
      for (const [key, project] of Object.entries(this.getProjects.answer)) {
        const email = this.getUsers.answer[project.userId].email;
        projects.push({ ...project, email, id: key });
      }
      if (this.$route.query.user) {
        projects = projects.filter(
          (project) => project.userId === this.$route.query.user
        );
      }
      return projects;
    },
    usersList() {
      const users = [{ value: null, text: "Все" }];
      for (const [key, user] of Object.entries(this.getUsers.answer)) {
        users.push({ value: key, text: user.email });
      }
      return users;
    },
  },
  methods: {
    ...mapActions("admin", [
      "requestUsers",
      "requestProjects",
      "setUserStatus",
    ]),
    filterByUser() {
      this.$router.push({ query: { user: this.filters.user } });
    },
  },
  created() {
    this.requestUsers();
    this.requestProjects();
    if (this.$route.query.user) {
      this.filters.user = this.$route.query.user;
    }
  },
};
</script>

<style></style>
