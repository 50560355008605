<template>
  <div>
    <vue-good-table
      :columns="columns"
      :rows="projects"
      :rtl="direction"
      :bordered="false"
      class="projects-table"
      styleClass="vgt-table"
    >
      <div slot="emptystate" class="text-center">
        Нет проектов
      </div>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'name'" class="text-nowrap ">
          <div
            class="text-nowrap cursor-pointer text-primary ml-2 mt-2"
            @click="
              $router.push({
                name: 'project-edit',
                params: { id: props.row.id, name: props.row.name },
              })
            "
          >
            {{ props.row.name }}
          </div>
        </span>
        <span
          v-else-if="props.column.field === 'email'"
          style="align-self: center"
        >
          <div class="text-nowrap mt-2">{{ props.row.email }}</div>
        </span>
        <span
          v-else-if="props.column.field === 'shows'"
          style="align-self: center"
        >
          <div class="text-nowrap mt-2 text-center">{{ props.row.shows }}</div>
        </span>
        <span v-else-if="props.column.field === 'action'">
          <span class="d-flex justify-content-around">
            <span style="align-self: center;">
              <b-button
                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                variant="outline-primary"
                pill
                @click="
                  $router.push({
                    name: 'project-preview',
                    params: { id: props.row.id, name: props.row.name },
                  })
                "
              >
                <feather-icon icon="PlayIcon" /> Предпросмотр
              </b-button>
            </span>
            <span style="align-self: center;" class="d-none d-md-flex">
              <b-button
                :id="`insertion-button${props.row.id}`"
                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                variant="outline-dark"
                @click="showWidgetModal(props.row.id)"
                pill
              >
                <feather-icon icon="CodeIcon" /> Код виджета
              </b-button>
            </span>
            <!-- <span style="align-self: center;" class="d-none d-md-flex">
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <b-button
                    v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                    variant="outline-dark"
                    pill
                  >
                    <feather-icon icon="SlidersIcon" /> Действия
                  </b-button>
                </template>
                <b-dropdown-item @click="modalRename(props.row.id)">
                  <feather-icon icon="EditIcon" />
                  Переименовать
                </b-dropdown-item>
              </b-dropdown>
            </span> -->
            <span style="align-self: center;" class="mx-2 mx-md-0">
              <feather-icon
                icon="Edit2Icon"
                size="18"
                class="text-body align-middle mr-25 cursor-pointer"
                @click="
                  $router.push({
                    name: 'project-edit',
                    params: { id: props.row.id, name: props.row.name },
                  })
                "
              />
            </span>
            <span style="align-self: center;" class="mx-2 mx-md-0">
              <feather-icon
                class="cursor-pointer"
                icon="BarChart2Icon"
                @click="
                  $router.push({
                    name: 'admin-statistics',
                    query: { project: props.row.id },
                  })
                "
                size="18"
              />
            </span>
          </span>
        </span>
      </template>
    </vue-good-table>
    <b-modal id="modal-widget" hide-footer centered>
      <template #modal-title>
        Код для встави виджета
      </template>
      <div class="d-block text-center">
        <b-row>
          <b-col md="12" class="mb-2">
            <label for="script-copy">
              Скопируйте внутрь {{ "<" }}body{{ ">" }}
            </label>
            <div style="position: relative" class="container">
              <feather-icon
                v-if="scriptString"
                icon="ClipboardIcon"
                style="position: absolute;top: 10px; right: 18px;"
                size="21"
                @click="doCopy(scriptString)"
              />
              <b-form-textarea v-model="scriptString" readonly no-resize>
              </b-form-textarea>
            </div>
          </b-col>
          <b-col md="12">
            <label for="script-copy">
              Вставьте виджет там, где вам удобно:
            </label>
            <div style="position: relative">
              <b-form-textarea
                v-model="inputString"
                rows="5"
                readonly
                no-resize
              >
              </b-form-textarea>
              <feather-icon
                v-if="inputString"
                icon="ClipboardIcon"
                style="position: absolute;top: 10px; right: 18px;"
                size="21"
                @click="doCopy(inputString)"
              />
            </div>
          </b-col>
          <b-col md="12">
            <label for="script-copy">
              ЛИБО, отправьте прямую ссылку на виджет:
            </label>
            <div style="position: relative">
              <b-form-textarea
                v-model="directString"
                rows="5"
                readonly
                no-resize
              >
              </b-form-textarea>
              <feather-icon
                v-if="directString"
                icon="ClipboardIcon"
                style="position: absolute;top: 10px; right: 18px;"
                size="21"
                @click="doCopy(directString)"
              />
            </div>
          </b-col>
        </b-row>
      </div>
      <b-button class="mt-3" block @click="$bvModal.hide('modal-widget')"
        >Закрыть</b-button
      >
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BForm,
  VBPopover,
  BPopover,
  BFormTextarea,
  BCol,
  BRow,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import Ripple from "vue-ripple-directive";
import { scriptString, widgetString } from "@/views/projects/edit-project/code";

export default {
  components: {
    VueGoodTable,
    VueGoodTable,
    BButton,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BForm,
    BPopover,
    BFormTextarea,
    BCol,
    BRow,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  props: ["projects"],
  data() {
    return {
      dir: false,
      rows: [],
      columns: [
        {
          label: "Название",
          field: "name",
        },
        {
          label: "Email",
          field: "email",
        },
        {
          label: "Показов",
          field: "shows",
        },
        {
          label: "",
          field: "action",
        },
      ],
      scriptString,
      inputString: "",
      directString: "",
      testString: "asdfasdf",
      message1: "Copy Me Without Directive",
    };
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  methods: {
    widgetInputString(id) {
      const baseUrl =
        (process.env.VUE_APP_DOMAIN ||
          "https://video-quest-dev.herokuapp.com/") + "vq/";
      console.log(baseUrl);
      this.inputString = `<iframe id="video-quest" src="${baseUrl}${id}" width="120" height="210" frameBorder="0"></iframe>`;
    },
    directInputString(id) {
      const baseUrl =
        (process.env.VUE_APP_DOMAIN ||
          "https://video-quest-dev.herokuapp.com/") + "direct/";
      this.directString = `${baseUrl}${id}`;
    },
    showWidgetModal(id) {
      this.widgetInputString(id);
      this.directInputString(id);
      this.$bvModal.show("modal-widget");
    },
    doCopy(string) {
      this.$copyText(string).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Текст скопирован",
            icon: "BellIcon",
          },
        });
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.projects-table tr:hover {
  background-color: #f8f8f8;
}
</style>
